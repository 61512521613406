import { IProductType } from 'productSelection/types/products';
import { ICLayerProduct } from 'shared/store/order/types';
import * as OrderAPI from 'shared/infra/commerceLayer/orders';
import { BUSINESS_ACCOUNT_PRODUCT } from 'shared/constants/ProductConstants';
import {
  dispatchAddProductEvent,
  dispatchAddProductFailureEvent,
  dispatchRemoveProductEvent,
  dispatchRemoveProductFailureEvent,
} from 'shared/services/tracker';
import { ICatalogState } from 'shared/store/catalog/types';
import { processCouponCodeToOrder } from 'shared/infra/commerceLayer/utils';

export type AddProductRequest = {
  sku: string;
  quantity: number;
  couponCode?: string;
  contentfulId: string;
  allowOnlyOne: boolean;
  locale: string;
  type: IProductType;
  localizedName?: string;
  businessAccount?: {
    enabled: boolean;
    selected: boolean;
  };
};

type TrackLineItemReq = {
  reference: string;
  trackingId: string;
  quantity: number;
  price: string;
};

type AddLineItemReq = TrackLineItemReq & {
  orderId: string;
  skuCode: string;
  type: IProductType;
  localizedName?: string;
};

type RemoveLineItemReq = TrackLineItemReq & {
  id: string;
};

const buildTrackEvent = (lineItem: TrackLineItemReq) => {
  return {
    product: {
      id: lineItem.reference,
      trackingId: lineItem.trackingId,
      quantity: lineItem.quantity,
      price: lineItem.price,
    },
  };
};

const removeLineItem = (req: RemoveLineItemReq) => {
  const trackEvent = buildTrackEvent(req);

  try {
    dispatchRemoveProductEvent(trackEvent);

    return OrderAPI.removeProduct(req.id);
  } catch (err) {
    dispatchRemoveProductFailureEvent(trackEvent);

    throw err;
  }
};

const addLineItem = async (req: AddLineItemReq) => {
  const trackEvent = buildTrackEvent(req);

  try {
    await OrderAPI.addProduct(
      req.orderId,
      req.skuCode,
      req.type,
      req.quantity,
      req.reference,
      req.localizedName,
    );

    dispatchAddProductEvent(trackEvent);
  } catch (err) {
    dispatchAddProductFailureEvent(trackEvent);

    throw err;
  }
};

const ensureBusinessAccountLineItemCreated = async (
  orderId: string,
  req: AddProductRequest,
  orderLineItems: ICLayerProduct[],
) => {
  if (!req.businessAccount?.enabled) {
    return;
  }

  const businessAccountProduct = orderLineItems.find(
    ({ skuCode }) => skuCode === BUSINESS_ACCOUNT_PRODUCT.sku,
  );

  if (req.businessAccount.selected && !businessAccountProduct) {
    const addLineItemReq = {
      orderId: orderId,
      skuCode: BUSINESS_ACCOUNT_PRODUCT.sku,
      quantity: 1,
      localizedName: BUSINESS_ACCOUNT_PRODUCT.name,
      reference: BUSINESS_ACCOUNT_PRODUCT.customId,
      formattedTotalAmount: BUSINESS_ACCOUNT_PRODUCT.price,
      trackingId: BUSINESS_ACCOUNT_PRODUCT.trackingId,
      type: BUSINESS_ACCOUNT_PRODUCT.type as IProductType,
      price: BUSINESS_ACCOUNT_PRODUCT.price,
    };

    return addLineItem(addLineItemReq);
  }

  if (!req.businessAccount.selected && businessAccountProduct) {
    const removeLineItemReq = {
      id: businessAccountProduct.id,
      reference: BUSINESS_ACCOUNT_PRODUCT.customId,
      quantity: 1,
      trackingId: BUSINESS_ACCOUNT_PRODUCT.trackingId,
      price: BUSINESS_ACCOUNT_PRODUCT.price,
    };

    return removeLineItem(removeLineItemReq);
  }
};

const cleanupRegularLineItems = async (
  req: AddProductRequest,
  orderLineItems: ICLayerProduct[],
  catalog: ICatalogState,
) => {
  if (!req.allowOnlyOne) {
    return;
  }

  const regularLineItems = orderLineItems.filter(
    (product) => product.skuCode !== BUSINESS_ACCOUNT_PRODUCT.sku,
  );

  await Promise.all(
    regularLineItems.map((product) => {
      const removeLineItemReq = {
        id: product.id,
        reference: product.reference,
        quantity: product.quantity,
        trackingId: catalog[product.reference]?.trackingId,
        price: catalog[product.reference]?.formattedAmount,
      };

      return removeLineItem(removeLineItemReq);
    }),
  );
};

const ensureRegularLineItemsCreated = async (
  orderId: string,
  req: AddProductRequest,
  orderLineItems: ICLayerProduct[],
  catalog: ICatalogState,
) => {
  await cleanupRegularLineItems(req, orderLineItems, catalog);
  await addLineItem({
    orderId: orderId,
    skuCode: req.sku,
    quantity: req.quantity,
    reference: req.contentfulId,
    localizedName: req.localizedName,
    price: catalog[req.contentfulId]?.formattedAmount,
    trackingId: catalog[req.contentfulId]?.trackingId,
    type: req.type,
  });
};

const ensureCouponCodeAttached = async (
  orderId: string,
  couponCode: string,
) => {
  if (!couponCode && couponCode !== '') {
    return;
  }

  return OrderAPI.updateOrder(orderId, {
    coupon_code: processCouponCodeToOrder(couponCode),
    _update_taxes: true,
  });
};

export const addProduct = async (
  orderId: string,
  req: AddProductRequest,
  orderLineItems: ICLayerProduct[],
  catalog: ICatalogState,
) => {
  await ensureRegularLineItemsCreated(orderId, req, orderLineItems, catalog);
  await ensureBusinessAccountLineItemCreated(orderId, req, orderLineItems);
  await ensureCouponCodeAttached(orderId, req.couponCode);
};
